div.api-attributes {
  padding: 0.1rem;
  font-size: small;
  border-style: solid;
  border-width: 1px;
  border-radius: 12px;
  border-color: #dee2e6;
}

div.api-attributes table th {
  border-top: none;
}

p.api-route {
  margin: 3px;
  padding-left: 5px;
  padding-right: 5px;
}

p.warning {
  color: #b66;
  font-style: italic;
  font-size: small;
}

.deprecated {
  color: #bbb;
}
.deprecated .api-route span {
  color: #bbb;
  text-decoration: line-through;
}

span.http-method {
  color: #a22;
  font-weight: bold;
}

span.api-base-url {
  color: #888;
}

span.api-path {
  color: #a62;
}

.variable {
  font-weight: bold;
  color: #a22;
}

.variable:before {
  content: "<";
}

.variable:after {
  content: ">";
}

/* Override Bootstrap code wrapping */
td code {
  white-space: pre;
  word-break: normal;
  word-wrap: normal;
}

/* This should be default in bootstrap but isn't working */
.card-block {
  padding: 1.25rem;
}
