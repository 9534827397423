div.api-attributes {
  border: 1px solid #dee2e6;
  border-radius: 12px;
  padding: .1rem;
  font-size: small;
}

div.api-attributes table th {
  border-top: none;
}

p.api-route {
  margin: 3px;
  padding-left: 5px;
  padding-right: 5px;
}

p.warning {
  color: #b66;
  font-size: small;
  font-style: italic;
}

.deprecated {
  color: #bbb;
}

.deprecated .api-route span {
  color: #bbb;
  text-decoration: line-through;
}

span.http-method {
  color: #a22;
  font-weight: bold;
}

span.api-base-url {
  color: #888;
}

span.api-path {
  color: #a62;
}

.variable {
  color: #a22;
  font-weight: bold;
}

.variable:before {
  content: "<";
}

.variable:after {
  content: ">";
}

td code {
  white-space: pre;
  word-break: normal;
  word-wrap: normal;
}

.card-block {
  padding: 1.25rem;
}

/*# sourceMappingURL=main.40fd3d13.css.map */
